import i18next from "i18next";
import { initReactI18next } from 'react-i18next';
import ru from './ru/translation.json';
import ua from './ua/translation.json';
import en from './en/translation.json';

const languages = ['ru', 'ua', 'en'];
const resources = { ru, ua, en };
i18next.use(initReactI18next).init({
  fallbackLng: languages[0],
  resources,
  supportedLngs: languages,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
  load: 'languageOnly',
});

export default i18next;
export { i18next as i18n };
