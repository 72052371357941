// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-consultation-form-js": () => import("./../../../src/pages/consultation-form.js" /* webpackChunkName: "component---src-pages-consultation-form-js" */),
  "component---src-pages-form-js": () => import("./../../../src/pages/form.js" /* webpackChunkName: "component---src-pages-form-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-presentation-form-js": () => import("./../../../src/pages/presentation-form.js" /* webpackChunkName: "component---src-pages-presentation-form-js" */),
  "component---src-pages-research-form-js": () => import("./../../../src/pages/research-form.js" /* webpackChunkName: "component---src-pages-research-form-js" */)
}

